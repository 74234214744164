import {
  Box,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { ActionButton } from "../../Components/Common/FormComponents";
import {
  LoginForm,
  LoginFormValues,
} from "../../Components/Forms/LoginForm/LoginForm";
import { RouteComponentProps } from "react-router-dom";
import { BlueLeapCard } from "../../Components/Common/BlueLeap";
import {
  ConfirmationCodeAlert,
  ErrorToast,
  InformationAlert,
  SuccessToast,
} from "../../Components/Common/Alerts";
import { AppContext } from "../../App";
import { AccountsClient } from "../../AccountsClient/AccountsClient";

let LoginFormContainer: React.FC<RouteComponentProps> = (props) => {
  let { returnUrl, firstLoginAfterRegistration, enteredUsername } = useContext(
    AppContext
  );

  const handleLoginSubmit = async (values: LoginFormValues) => {
    let username = values.username;
    let password = values.password;

    const handleTwoFactorLogin = async () => {
      let loggedInWith2FA = false;
      while (!loggedInWith2FA) {
        const confirmationCode: string = (
          await ConfirmationCodeAlert.fire({
            text: "Enter the code received on your phone",
          })
        ).value as string;

        const twoFactorLoginResponse = await AccountsClient.TwoFactorLogin(
          returnUrl ?? "",
          confirmationCode
        );
        if (twoFactorLoginResponse != null) {
          SuccessToast.fire({
            text: "Logged in successfully",
          });
          loggedInWith2FA = true;
          window.location.href = twoFactorLoginResponse.redirectUrl;
          break;
        }
      }
    };

    const handleAccountConfirmation = async () => {
      let emailConfirmed = false;
      while (!emailConfirmed) {
        let confirmationCode = (
          await ConfirmationCodeAlert.fire({
            text: "Check your email",
          })
        ).value as string;
        let registerConfirmEmailResponse = await AccountsClient.RegisterConfirmEmail(
          confirmationCode,
          username
        );
        if (registerConfirmEmailResponse != null) {
          SuccessToast.fire({
            text: "Confirmed email successfully",
          });
          emailConfirmed = true;
          handleLoginSubmit(values); // simulate logging in again
          break;
        }
        else
        {
          ErrorToast.fire({
            text: "Failed to confirm your email. Please retry.",
          });
          await new Promise((r) => setTimeout(r, 2000));
        }
      }
    };
    const loginResponse = await AccountsClient.Login(
      username,
      password,
      returnUrl ?? ""
    );
    if (loginResponse != null) {
      if (loginResponse.requiresFurtherAction) {
        switch (loginResponse.furtherActionStatus) {
          case "TwoFactorLoginRequired": {
            await handleTwoFactorLogin(); break;
          }
          case "AccountConfirmationRequired": {
            await handleAccountConfirmation(); break;
          }
        }
      } else {
        SuccessToast.fire({ text: "Logged in successfully" });
        await new Promise((r) => setTimeout(r, 500));
        window.location.href = loginResponse.redirectUrl;
      }
    }
    else
    {
      ErrorToast.fire({ text: "Failed to log in successfully" });
      await new Promise((r) => setTimeout(r, 500));
    }
  };

  return (
    <Container maxWidth="md">
      <Box
        pt={6}
        maxWidth={{ xs: "100%", sm: "85%", md: "100%" }}
        display="flex"
        justifyContent="center"
        margin="auto"
      >
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item md style={{ width: "100%" }}>
            <BlueLeapCard>
              <Box>
                <CardHeader
                  title={
                    <Box
                      textAlign="left"
                      fontSize="h3.fontSize"
                      fontWeight="400"
                      children={"New Customer"}
                      color="primary.main"
                    />
                  }
                />
                <CardContent>
                  <Box color="secondary.main" textAlign="left">
                    <span>By clicking Register, you agree to our </span>
                    <Link
                      href="https://qa.blueleap.com/terms-of-service/"
                      underline="always"
                      style={{ textDecorationStyle: "dashed" }}
                    >
                      <Box
                        display="inline"
                        textAlign="left"
                        children={" Terms of Service "}
                        color="secondary.main"
                      />
                    </Link>
                    <span> and </span>
                    <Link
                      href="https://qa.blueleap.com/privacy-policy/"
                      underline="always"
                      style={{ textDecorationStyle: "dashed" }}
                    >
                      <Box
                        display="inline"
                        textAlign="left"
                        children={"Privacy Policy"}
                        color="secondary.main"
                      />
                    </Link>
                    .
                  </Box>
                  <Box mt={2} display="flex" justifyContent="flex-start">
                    <ActionButton
                      fullWidth={false}
                      onClick={() => {
                        props.history.replace("/register");
                      }}
                    >
                      Register
                    </ActionButton>
                  </Box>
                </CardContent>
              </Box>
            </BlueLeapCard>
          </Grid>
          <Grid item md style={{ width: "100%" }}>
            <BlueLeapCard>
              <Box>
                <CardHeader
                  title={
                    <Box
                      textAlign="left"
                      fontSize="h3.fontSize"
                      fontWeight="400"
                      children={"Returning Customer"}
                      color="primary.main"
                    />
                  }
                />
                <CardContent>
                  <LoginForm
                    submitButtonText="Login"
                    onSubmit={handleLoginSubmit}
                    initialUsername={enteredUsername ?? undefined}
                  />
                </CardContent>
              </Box>
            </BlueLeapCard>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export const LoginPage: React.FC<RouteComponentProps> = (props) => {
  return <LoginFormContainer {...props} />;
};
